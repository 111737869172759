import AppRoutes from './AppRoutes'
import Layout from './components/Layout'
import NotFound from './pages/Errors/NotFound'
import AuthenticationProvider from './auth'

export default function App() {
  if (process.env.NODE_ENV === 'development')
    return (
      <AuthenticationProvider>
        <Layout>
          <AppRoutes />
        </Layout>
      </AuthenticationProvider>
    )
  return <NotFound />
}
