import { red } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'

const lightTheme = {
  palette: {
    text: {
      primary: '#143c5a',
      secondary: 'rgba(0, 28, 66, 0.54)',
      disabled: 'rgba(0, 28, 66, 0.38)',
      hint: 'rgba(0, 28, 66, 0.38)',
    },
    primary: {
      main: '#143c5a',
      light: '#445671',
      dark: '#00011f',
      contrast: '#fff',
    },
    secondary: {
      main: '#1996eb',
      light: '#69c6ff',
      dark: '#0069b8',
      contrast: '#fff',
    },
    error: {
      main: red.A400,
    },
  },
}

const darkTheme = {
  palette: {
    mode: 'dark',
    text: {
      primary: '#adbac7',
      secondary: 'rgba(173, 186, 199, 0.67)',
      disabled: 'rgba(255, 255, 255, 0.5)',
      hint: 'rgba(255, 255, 255, 0.5)',
    },
    primary: {
      main: '#adbac7',
      light: '#bdc7d2',
      dark: '#79828b',
      contrast: 'rgba(0, 0, 0, 0.87)',
    },
    secondary: {
      main: '#0069b8',
      light: '#3387c6',
      dark: '#004980',
      contrast: '#ffffff',
    },
  },
}

function getUserPrefPaletteMode() {
  return (
    document.cookie
      .split(';')
      .map(x => x.trim())
      .map(x => x.split('='))
      .find(x => x[0] === 'theme-palette-mode')

      ?.pop() || 'light'
  )
}

// A custom theme for this app
const theme = createTheme(
  getUserPrefPaletteMode() === 'dark' ? darkTheme : lightTheme,
)

export default theme
