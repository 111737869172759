import { Box, Typography } from '@mui/material'

export default function NotFound() {
  return (
    <Box m={2}>
      <Typography variant="h3">HTTP Error 404.0 - Not Found</Typography>
      <p>
        The resource you are looking for has been removed, had its name changed,
        or is temporarily unavailable.
      </p>
    </Box>
  )
}
