import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

const AuthenticationProvider = ({ children }) => {
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const [authenticated, setAuthenticated] = useState(false)
  useEffect(() => {
    const fetchAuthentication = async () => {
      const response = await fetch('checkauth', { redirect: 'manual' })
      const { type } = response
      if (type === 'opaqueredirect') {
        setAuthenticated(false)
        setLoading(false)
      } else {
        const isAuthenticated = await response.json()
        setAuthenticated(isAuthenticated)
        setLoading(false)
      }
    }
    fetchAuthentication()
  }, [])
  if (loading) return <>Loading...</>
  if (authenticated) return <>{children}</>
  window.location.replace(
    `Identity/account/login?returnUrl=${location.pathname}`,
  )
}

AuthenticationProvider.propTypes = {
  children: PropTypes.node,
}
export default AuthenticationProvider
