import { Box, Button, Divider, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import styled from 'styled-components/macro'

const ResultWrapper = styled(Box)`
  margin: ${({ theme }) => theme.spacing(2)} 0;
`

interface Props {
  value?: number
  onIncrement?: () => void
  onDecrement?: () => void
  onIncrementAsync?: () => void
  onDecrementAsync?: () => void
}

export default function Counter({
  value,
  onIncrement,
  onDecrement,
  onIncrementAsync,
  onDecrementAsync,
}: Props) {
  return (
    <>
      <Typography variant="h4" gutterBottom>
        Counter
      </Typography>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
      >
        <Button onClick={onIncrement} variant="contained">
          + Increment
        </Button>
        <Button onClick={onDecrement} variant="contained">
          - Decrement
        </Button>
        <Button onClick={onIncrementAsync} variant="outlined">
          + Increment after 1 second
        </Button>
        <Button onClick={onDecrementAsync} variant="outlined">
          - Decrement after 1 second
        </Button>
      </Stack>
      <ResultWrapper>Clicked {value} times</ResultWrapper>
    </>
  )
}
